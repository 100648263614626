<script setup>
import SideMenu from '~/components/SideMenu.vue'
import Navbar from '~/components/Navbar.vue'
import { useAuthStore } from '~/store/auth.js'
import '~/assets/css/app-layout.scss'

const { $Sentry } = useNuxtApp()

const userStore = useAuthStore()
const user = computed(() => userStore.user)

onMounted(() => {
  if ($Sentry && user.value) {
    $Sentry.setUser({
      id: user.value?.app_user_ref,
      username: user.value?.full_name,
      email: user.value?.email,
    })
  }
})

onBeforeUnmount(() => {
  if ($Sentry) {
    $Sentry.setUser(null)
    $Sentry.close()
  }
})

function handleLogout() {
  userStore.logout()
}
</script>

<template>
  <environment-banner />
  <div class="flex h-screen w-screen bg-gray-100">
    <SideMenu :user="user" />
    <main class="relative flex h-full flex-1 flex-col gap-6 overflow-auto">
      <Navbar :user="user" @logout="handleLogout" />
      <section class="px-2 pb-6 md:px-6">
        <slot />
      </section>
    </main>
  </div>
</template>
